#root {
  margin: 0 auto;
  /* text-align: center; */
}
/* *{
  overflow: hidden;
} */
/*

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

 */
#app {
  padding: 0;
  margin: 0;
}
* {
  margin: 0;
  box-sizing: border-box;
}
html {
  height: 100%;
  position: relative;
}

body {
  min-height: 100vh;
}
button {
  cursor: pointer;
}
.Wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}
header {
  background-color: #354f52;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 8rem 13rem;
}
.headerLogo img {
  height: clamp(1rem, 6.9vw, 2.5rem);
  width: 100%;
}
.headerMenu .menuList {
  display: flex;
  text-decoration: none;
  list-style: none;
  gap: 2rem;
}
.headerMenu .burger {
  display: none;
  color: white;
}
.headerMenu .xmark {
  display: none;
  position: fixed;
  color: white;
  z-index: 4;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #354f52;
  min-width: 160px;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
.menuList li a {
  color: #cad2c5;
}
main {
  /* background-color: yellow;  */
  /* height: 90rem; */
  /* width: 100px; */
}
.headerTitle {
  font-size: clamp(1rem, 1.9vw, 2rem);
  color: #cad2c5;
  text-align: center;
}
.container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.heroSection {
  height: clamp(12rem, 33vw, 26rem);
  background: #242424;
}
.heroSectionPrice {
  height: clamp(12rem, 35vw, 29rem);
  background: #242424;
}
.heroSlide {
  width: 100%;
  height: 100%;
}
.heroSlide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.priceListContainer {
  overflow: hidden;
}
.card {
  display: inline-block;
  width: clamp(8.5rem, 18vw, 30rem);
  userselect: none;
}
.cardTitle {
  font-size: clamp(1.2rem, 2.3vw, 2rem);
}
.produkCard h2 {
  font-size: clamp(1.1rem, 1.9vw, 1.5rem);
}
.produkCard p {
  font-size: 1.3rem;
}
.scroll {
  display: block;
  margin-left: 5rem;
  margin-right: auto;
  width: 98%;
  /* background: red; */
}
.produkList {
  display: grid;
  grid-template-columns: repeat(4, 17%);
  gap: clamp(4rem, 8vw, 8rem);
  justify-content: center;
}
.produkCard {
  display: grid;
  text-align: left;
}
.produkCard img {
  width: 100%;
}
.aboutUsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: clamp(3.5rem, 8vw, 8rem);
  padding-inline: 5rem;
}

.aboutCompany {
  width: 50%;
  color: white;
  text-align: centre;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.aboutCompany p {
  text-align: justify;
  font-size: clamp(1rem, 1.9vw, 2rem);
  line-height: clamp(1.5rem, 2.8vw, 3rem);
}
.aboutContact {
  width: 40%;
}
.contactCard {
  padding-block: 2rem;
  display: grid;
  gap: 1rem;
  background-color: #cad2c5;
  /* border-radius: 30px; */
  color: black;
}
.cardLogo {
  display: flex;
  justify-content: space-between;
  margin-inline: 2rem;
}
.cardLogo img {
  height: clamp(1.2rem, 2.3vw, 2.5rem);
}
.cardProfile {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: clamp(1rem, 2vw, 2rem);
  align-items: center;
}
.cardProfile img {
  width: clamp(5rem, 10vw, 10rem);
  height: clamp(5rem, 10vw, 10rem);
  border-radius: 50%;
}
.profileDesc {
  font-size: clamp(0.8rem, 1.5vw, 2rem);
  margin-right: 1rem;
}
.cardContact,
.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
  margin-left: 6rem;
}
.cardContact div,
footer .profile div {
  display: flex;
  justify-content: left;
  gap: 2rem;
}
.cardContact h2 {
  font-size: clamp(1rem, 1.6vw, 1.3rem);
  margin-top: 0.5rem;
}
.cardContact h2 a {
  color: black;
  text-decoration: none;
}
footer .profile h2 a {
  color: #cad2c5;
  text-decoration: none;
}
.cardContact h2 .iconUtil {
  font-size: clamp(1rem, 2vw, 1.5rem);
}
footer .profile h2 .iconUtil {
  font-size: clamp(1rem, 2vw, 1.1rem);
}
.testimoniContainer {
  margin-bottom: 2rem;
}
.testimoniSection .testimoniList {
  display: flex;
  justify-content: center;
  gap: clamp(1rem, 8.5vw, 7rem);
}
.testimoniSection .testimoniCard {
  width: clamp(10rem, 37vw, 30rem);
  display: flex;
  flex-direction: column;
}
.testimoniCard img {
  width: 70%;
  margin: 0 auto;
}
.testimoniCard p {
  font-size: clamp(0.8rem, 1.3vw, 1rem);
  text-align: center;
}
footer {
  background-color: #354f52;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 8rem 13rem;
  padding-block: 1rem;
  color: #cad2c5;
  margin-top: auto;
}
.headerLogo img {
  height: clamp(0.8rem, 6.9vw, 2.3rem);
}

.profile h2 {
  font-size: clamp(0.8rem, 1.2vw, 0.8rem);
  margin-top: 0.5rem;
}
footer .profile div {
  gap: 1rem;
}

.footerMenu .menuList {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  list-style: none;
  gap: 0.5rem;
  text-align: left;
}
.footerMenu .menuList li a {
  font-size: clamp(0.8rem, 1.2vw, 0.8rem);
}

/* PriceList Screen */

.priceListProduct {
  padding: 3rem 7rem;
}
.priceListProduct .priceListProductContainer {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.priceListProduct .priceListProductContainer img {
  height: clamp(9rem, 19vw, 15rem);
  margin-top: 2rem;
}
.priceListProduct .producType {
}
.priceListProduct .tableRow {
  text-align: left;
  width: clamp(10rem, 49vw, 40rem);
  border-spacing: 0rem 1.5em;
}
.tableRow tr {
  height: 3rem;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
}
.tableRow tr td:nth-child(1) {
  width: clamp(15rem, 25vw, 25rem);
}
.tableRow tr th {
  text-align: center;
}
.tableRow tr td:nth-child(2) {
  text-align: right;
}

/* Testimoni Screen */
.testimoniPage {
}
.testimoniPageContainer {
  padding: 3rem 5rem;
  display: grid;
  gap: 2rem;
  text-align: left;
}
.testimoniCardPageContainer {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 30%);
  gap: clamp(1rem, 3.6vw, 3rem);
}
.testimoniCardPage {
  width: 100%;
  position: relative;
}
.testimoniPageImage {
  width: 100%;
  background: black;
  opacity: 0.5;
}
.modal {
  position: fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.open {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  display: block;
  padding-inline: 2rem;
  border-radius: 12px;
  color: white;
  border: solid 1px white;
  z-index: 1;
}
.dialog {
  box-shadow: 0 8px 6px -6px black;
  position: static;
  left: 20%;
  top: 10%;
}

.image {
  width: 300px;
}
.openBtn {
  color: white;
}
.cancel {
  position: absolute;
  top: 20px;
  left: 30px;
  width: calc(100% - 30px);
  height: calc(100% - 20px);
  font-size: 2rem;
  color: white;
  cursor: pointer;
}
#todiv1:target {
  display: none;
}
#todiv1:target {
  display: block;
}
#todiv1:target ~ .modal {
  height: 100%;
  width: 100%;
}
.testimoniPageImageLarge {
  margin: auto;
  display: block;
  width: 40%;
}
.testimoniCardPage:hover .testimoniPage {
  background: black;
}
.testimoniCardPage .testimoniText {
  font-size: clamp(0.8rem, 1.3vw, 1rem);
  margin: 0 auto;
  width: 50%;
  margin-top: 2rem;
  text-align: center;
  line-height: 1.8em;
}
.spinLoader {
  position: absolute;
  display: grid;
  justify-content: center;
  align-items: center;
  /* margin-top: 50%; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  /* transform: translateX(-50%); */
}

.otherButton .loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: #0096ff;
  border: none;
}
.warnInputLogin {
  margin-bottom: 1rem;
}
.warnInputLogin {
  background: green;
}
.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}
.loaderPage {
  border: 20px solid #f3f3f3;
  border-top: 20px solid #3498db;
  border-radius: 50%;
  width: 126px;
  height: 126px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Vehicle Screen */
.vehiclePage {
  padding: 3rem 8rem;
}
.vehicleListContainer {
  align-self: center;
}
.headerVehicle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 2rem;
}
.addButtonAdmin {
  cursor: pointer;
  background: blue;
  padding: 0.5rem 2rem;
}
.addButtonAdmin h3 {
  font-size: clamp(0.8rem, 1.5vw, 1.17rem);
}
.adminTable {
  width: 100%;
  border-collapse: seperate;
  margin: 0;
  padding: 0;
  border-spacing: 1.5rem 1.5em;
}
.adminTable th {
  font-size: clamp(0.8rem, 1.3vw, 1rem);
}
.adminTable td {
  font-size: clamp(0.6rem, 1.3vw, 1rem);
  vertical-align: middle;
  text-align: left;
}
.adminTable h3 {
  margin-left: 60%;
  transform: translateX(-60%);
}
.subvehicleTable .nameCell h2 {
  margin-left: 20%;
}
.subvehicleTable .kategoriCell h2 {
  margin-left: 30%;
}
.subvehicleTable .tipeHead {
  /* background: red; */
  width: clamp(10rem, 91vh, 30rem);
  /* width: 30rem; */
}

.vehicleImage,
.vehicleBanner {
  height: clamp(3rem, 7.3vw, 6rem);
  display: block;
  margin: 0 auto;
}
.buttonAction {
  /* background: red;
  width: 15rem;; */
}

.tipe {
  background: #0d7070;
  display: block;
  margin: 0 auto;
}
.delete {
  background: #ff0000;
  display: block;
  margin: 0 auto;
}
.edit {
  background: #257300;
  display: block;
  margin: 0 auto;
}
.btn {
  display: flex;
  width: max-content;
  justify-content: center;
  padding: 0.3rem 1rem;
  color: white;
  font-size: clamp(0.5rem, 2.7vw, 0.8rem);
  margin-bottom: 0.5rem;
}
.bannerTable {
  width: 60%;
  border-spacing: 0.5rem 1.5em;
}
.bannerTable .imageBanner {
  display: flex;
  justify-content: flex-start;
  width: clamp(17rem, 71.2vw, 30rem);
}
.bannerTable .vehicleBanner {
  height: clamp(5rem, 21vw, 9rem);
}
.actionCell .btn {
  width: 5rem;
  border: none;
}

/* Vehicle Form */
.textWarnInput {
  background: red;
  padding: 0.5rem;
  text-align: left;
}
.textSuccessText {
  background: green;
  padding: 0.5rem;
  text-align: left;
}
.vehicleForm {
  margin-top: 2rem;
}
.vehicleForm .formContainer {
  padding-inline: 2rem;
}
.formContainer form {
  display: grid;
  gap: 1.5rem;
}
.formInput {
  display: grid;
  gap: 0.5rem;
  width: 65%;
}
.formInput input,
.formInput select {
  height: 2.5rem;
  padding-inline: 0.2rem;
  border: 3px solid #ccc;
  background: #ccc;
  color: #242424;
}
.menuPictContainer {
  border: 1px solid #ccc;
  background: #ccc;
  padding: 0.5rem;
  color: #242424;
}
.menuPictContainer #menuPict {
  height: 100%;
  display: inline-block;
}
.buttons .saveButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 65%;
  height: 2.5rem;
  background: blue;
  border: none;
}
.adminTable .nameCell p {
  /* font-size: clamp(.7rem, 1.4vw, 1rem); */
  font-size: clamp(0.7rem, 1.4vw, 1rem);
  text-align: justify;
}

@media screen and (max-width: 1024px) {
  .produkList {
    display: grid;
    grid-template-columns: repeat(4, 18%);
    gap: 3rem;
  }
  .produkCard p {
    font-size: 1rem;
  }
  .vehiclePage {
    padding: 3rem 5rem;
  }
  .subvehicleTable .kategoriCell h2 {
    margin-left: 25%;
    /* transform: translateX(-50%) */
  }
}
@media screen and (max-width: 968px) {
  header {
    padding-inline: 3rem 5rem;
  }
  .dropdown-content {
    right: 0;
  }
  /* .headerMenu .menuList {
    gap: 0.5rem;
  } */
  .cardContact,
  .profile {
    margin-left: 2rem;
  }
  .cardContact div,
  footer .profile div {
    gap: 1rem;
  }
  footer {
    padding-inline: 2.5rem;
  }
  .priceListProduct {
    padding: 3rem 5rem;
  }
  .scroll {
    margin-left: 4rem;
  }
  .testimoniPageContainer {
    padding: 3rem 3rem;
  }
  .headerVehicle {
    margin-bottom: 2rem;
    padding-inline: 1rem 0rem;
  }

  .vehiclePage {
    padding: 3rem 3rem;
  }
  .adminTable {
    border-spacing: 0rem 1em;
  }
  .adminTable .nameCell {
    /* padding-inline: 2rem; */
  }
  .imageCell {
    width: clamp(6rem, 13.2vw, 8rem);
  }
  .imageBanner {
    width: clamp(6rem, 13.2vw, 8rem);
  }
  .adminTable h3 {
    margin-left: 60%;
    transform: translateX(-60%);
  }
  .subvehicleTable th {
    font-size: clamp(0.8rem, 1.7vw, 1rem);
  }
  .subvehicleTable td {
    font-size: clamp(0.6rem, 1.7vw, 1rem);
  }
  .subvehicleTable .kategoriCell h2 {
    margin-left: 20%;
    /* transform: translateX(-50%) */
  }
}
@media screen and (max-width: 768px) {
  .produkList {
    grid-template-columns: repeat(3, 20%);
    gap: clamp(2rem, 11vw, 5rem);
  }
  .aboutUsContainer {
    /* gap: clamp(3.5rem, 8vw, 8rem); */
    gap: 2rem;
    padding-inline: 3rem;
  }
  .cardContact,
  .profile {
    margin-left: 6rem;
  }
  .cardContact div,
  footer .profile div {
    gap: 2rem;
  }
  .priceListProduct {
    padding: 3rem 2rem;
  }
  .scroll {
    margin-left: 2rem;
  }
  .priceListProduct .priceListProductContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .priceListProduct .priceListProductContainer img {
    /* height: clamp(10rem, 19vw, 15rem); */
    margin-top: 0;
  }
  .priceListProduct .producType {
  }
  .priceListProduct .tableRow {
    text-align: left;
    width: clamp(20rem, 85vw, 40rem);
    border-spacing: 0rem 1.2rem;
  }
  .tableRow tr {
    height: clamp(2.8rem, 7vw, 3rem);
    font-size: clamp(0.8rem, 3vw, 1.2rem);
  }
  .tableRow tr td:nth-child(1) {
    width: clamp(10rem, 48vw, 23rem);
  }
  .testimoniPageContainer {
    padding: 3rem 2rem;
  }
  .testimoniPageImageLarge {
    width: 50%;
  }
  .subvehicleTable th {
    font-size: clamp(0.8rem, 2.5vw, 1rem);
  }
  .subvehicleTable td {
    /* font-size: clamp(.6rem, 2.5vw, 1rem); */
    /* font-size: clamp(.6rem, 1.5vw, 1rem); */
  }
  .subvehicleTable .tipeHead {
    width: 20rem;
  }
  .subvehicleTable .kategoriCell h2 {
    margin-left: 30%;
    /* transform: translateX(-50%) */
  }
  .formInput,
  .buttons .saveButton {
    width: 80%;
  }
}
@media screen and (max-width: 660px) {
  header {
    padding-inline: 2rem 4rem;
  }
  .headerMenu .menuList {
    font-size: 0.9rem;
  }
  .scroll {
    margin-left: 1rem;
  }
  .produkList {
    grid-template-columns: repeat(2, 28%);
    gap: clamp(2rem, 20vw, 8rem);
  }
  .aboutUsContainer {
    flex-direction: column;
    gap: 1rem;
    padding-inline: 5rem;
  }
  .cardContact,
  .profile {
    margin-left: 4rem;
  }

  .aboutContact,
  .aboutCompany {
    width: 100%;
  }
  .cardLogo img {
    height: clamp(1.2rem, 6.7vw, 2rem);
  }
  .cardProfile {
    gap: clamp(1rem, 2vw, 2rem);
    align-items: center;
  }
  .cardProfile img {
    width: clamp(5rem, 26vw, 7rem);
    height: clamp(5rem, 26vw, 7rem);
  }
  .profileDesc {
    font-size: clamp(0.8rem, 5vw, 1.3rem);
  }
  .cardContact h2 {
    font-size: clamp(1rem, 4.8vw, 1.5rem);
  }
  .cardContact h2 .iconUtil {
    font-size: clamp(1rem, 5vw, 1.5rem);
  }
  .testimoniSection .testimoniList {
    flex-direction: column;
    align-items: center;
  }
  .testimoniSection .testimoniCard {
    width: clamp(10rem, 75vw, 23rem);
  }
  .testimoniCard img {
    width: 65%;
  }
  .testimoniCard p {
    font-size: clamp(0.8rem, 3.3vw, 1rem);
    text-align: center;
  }
  footer {
    padding-inline: 1.5rem;
  }
  .testimoniCardPageContainer {
    grid-template-columns: repeat(2, 50%);
  }
  .testimoniPageImageLarge {
    width: 70%;
  }
  .vehiclePage {
    padding: 3rem 1rem;
  }
  .subvehicleTable th {
    font-size: clamp(0.8rem, 2vw, 0.8rem);
  }
  .subvehicleTable td {
    /* font-size: clamp(.6rem, 2vw, .8rem); */
  }

  .subvehicleTable .tipeHead {
    width: 15rem;
  }
  .subvehicleTable .nameCell h2 {
    margin-left: 10%;
    /* transform: translateX(-50%) */
  }
  .subvehicleTable .kategoriCell h2 {
    margin-left: 25%;
    /* transform: translateX(-50%) */
  }
  .btn {
    font-size: 0.7rem;
  }
  .formInput,
  .buttons .saveButton {
    width: 90%;
  }
}
@media screen and (max-width: 490px) {
  header {
    padding-inline: 3rem 4rem;
  }

  .headerMenu .menuList {
    display: none;
  }
  .headerMenu .burger {
    display: inline;
  }
  .headerMenu .showxmark {
    display: block;
  }

  .show {
    display: flex;
    margin-right: 50%;
    transform: translateX(50%);
  }

  header {
    background-color: #354f52;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-inline: 8rem 13rem; */
  }

  /* .headerLogo img {
    height: clamp(1rem, 6.9vw, 2.5rem);
  } */
  .headerMenu .showHeader {
    display: grid;
    /* gap: 1rem; */
    justify-content: center;
    padding-block: 7rem 5rem;
    background-color: #354f52;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
  }
  .heroSection {
    height: clamp(3rem, 33vw, 10rem);

    background: yellow;
  }
  .container {
    margin-top: 5rem;
  }
  .aboutUsContainer {
    gap: 1rem;
    padding-inline: 2rem;
  }

  .footerContainer .profile {
    margin-left: 1rem;
  }
  .cardContact div,
  footer .profile div {
    gap: 1rem;
  }
  footer {
    flex-direction: column;
    gap: 0.5rem;
    padding-block: 0.5rem;
  }
  .footerMenu .menuList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  .profile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  .vehiclePage {
    padding: 3rem 1rem;
  }
  .headerVehicle {
    padding-inline: 1.5rem;
  }
  .vehicleListContainer {
    display: grid;
  }
  .vehicleTable {
    border: 1px solid #837979;
    border-collapse: seperate;
    padding-inline: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: clamp(22rem, 83vw, 25rem);
    table-layout: fixed;
    color: #ffffff;
  }

  .vehicleTable caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  .vehicleTable tr {
    background-color: #354f52;
    border: 1px solid #837979;
    padding: 0.35em;
  }

  .vehicleTable th,
  .vehicleTable td {
    padding: 0.625em;
    text-align: center;
  }

  .vehicleTable th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
  }
  .vehicleTable {
    border: 0;
  }

  .vehicleTable caption {
    font-size: 1.3em;
  }

  .vehicleTable thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .imageCell {
    width: initial;
  }
  .imageBanner {
    width: initial;
  }
  .vehicleTable tr {
    border-bottom: 3px solid #837979;
    display: block;
    margin-bottom: 0.625em;
  }

  .vehicleTable td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }
  .vehicleTable td:nth-child(3),
  .vehicleTable td:nth-child(4) {
    border-bottom: 3px solid #2f3e46;
    text-align: right;
  }
  .adminTable h3 {
    margin-left: 0%;
    transform: translateX(0%);
  }
  .actionCellButton {
    margin-left: 17rem;
    display: flex;
    background: red;
  }
  .vehicleTable .nameCell {
    display: flex;
    justify-content: space-between;
  }
  .vehicleTable .nameCell p {
    padding-left: 4rem;
    text-align: justify;
    /* width: 100%; */
  }

  .vehicleTable td::before {
    /*
    * aria-label has no advantage, it won't be read inside a .vehicleTable
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    /* text-transform: uppercase; */
  }
  .vehicleTable td,
  th {
    font-size: 0.7rem;
  }

  .vehicleImage,
  .vehicleBanner {
    height: clamp(3rem, 23vw, 6rem);
    display: initial;
  }

  .vehicleTable td:last-child {
    border-bottom: 0;
  }

  .subvehicleTable .nameCell,
  .subvehicleTable .kategoriCell {
    width: 7rem;
  }
  .subvehicleTable .tipeHead {
    width: 10rem;
  }
  .subvehicleTable .nameCell h2 {
    margin-left: 5%;
    font-size: 0.75rem;
    /* transform: translateX(-50%) */
  }
  .subvehicleTable .kategoriCell h2 {
    /* margin-left: 20%;  */
    font-size: 0.75rem;
    /* transform: translateX(-50%) */
  }
  .actionCell {
    width: 4rem;
  }
  .addButtonAdmin {
    background: blue;
    cursor: pointer;
    padding: 0.5rem 1rem;
  }
  .vehicleTable .actionCell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background: red; */
  }
  .buttonAction {
    display: flex;
    /* justify-content: flex-end; */
    /* margin-inline: 5.5rem 2rem; */
    /* margin-left: 5.5rem; */
    gap: clamp(0.5rem, 2.6vw, 0.8rem);
  }
  .actionCell .btn {
    width: clamp(3.6rem, 14vw, 4.3rem);
    /* width: 4.3rem; */
  }
  /* .btn{
    padding: .3rem 1rem;
    color: white;
  } */
  .vehicleForm .formContainer {
    padding-inline: 0.8rem;
  }
}
